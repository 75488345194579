import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const DynamicLink = ({ className, children, to, useIcon }) => {
  if (to.substring(0, 1) === '/') {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={className}
        href={to}
        target="_blank"
        rel="noopener noreferrer">
        {children}
        {useIcon && ' '}
        {useIcon && <i className="mdi mdi-24px mdi-call-made" />}
      </a>
    );
  }
};

DynamicLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  to: PropTypes.string,
  useIcon: PropTypes.bool,
};

export default DynamicLink;
