import React from 'react';

import DynamicLink from './DynamicLink.js';

const Footer = () => (
  <div className="footer">
    <p className="credits">
      Credits: This site was designed and developed by me using{' '}
      <DynamicLink to="https://www.gatsbyjs.org/">GatsbyJS</DynamicLink>. The text
      is set in Migra and Neue Montreal, both by{' '}
      <DynamicLink to="https://pangrampangram.com/">
        Pangram Pangram
      </DynamicLink>
      . The ASCII art is my own but inspired by the work of{' '}
      <DynamicLink to="https://www.oocities.org/spunk1111/indexjava.htm">
        Joan Stark
      </DynamicLink>
      .
    </p>
  </div>
);

export default Footer;
